:root,
html {
  font-size: 10px;
}

body {
  font-size: 1.5rem !important;
  height: 100vh;
  font-family: "lato-regular", sans-serif;
}

@font-face {
  font-family: "lato-light";
  font-weight: 300;
  font-style: normal;
  src: url("https://webfonts.zohowebstatic.com/lato2light/font.eot");
  src: url("https://webfonts.zohowebstatic.com/lato2light/font.eot?#iefix") format("eot"), url("https://webfonts.zohowebstatic.com/lato2light/font.woff2") format("woff2"), url("https://webfonts.zohowebstatic.com/lato2light/font.woff") format("woff"), url("https://webfonts.zohowebstatic.com/lato2light/font.ttf") format("truetype"), url("https://webfonts.zohowebstatic.com/lato2light/font.svg#Lato2-Light") format("svg");
}

@font-face {
  font-family: "lato-regular";
  font-weight: 400;
  font-style: normal;
  src: url("https://webfonts.zohowebstatic.com/lato2regular/font.eot");
  src: url("https://webfonts.zohowebstatic.com/lato2regular/font.eot?#iefix") format("eot"), url("https://webfonts.zohowebstatic.com/lato2regular/font.woff2") format("woff2"), url("https://webfonts.zohowebstatic.com/lato2regular/font.woff") format("woff"), url("https://webfonts.zohowebstatic.com/lato2regular/font.ttf") format("truetype"), url("https://webfonts.zohowebstatic.com/lato2regular/font.svg#Lato2-Regular") format("svg");
}

@font-face {
  font-family: "lato-medium";
  font-weight: 500;
  font-style: normal;
  src: url("https://webfonts.zohowebstatic.com/lato2medium/font.eot");
  src: url("https://webfonts.zohowebstatic.com/lato2medium/font.eot?#iefix") format("eot"), url("https://webfonts.zohowebstatic.com/lato2medium/font.woff2") format("woff2"), url("https://webfonts.zohowebstatic.com/lato2medium/font.woff") format("woff"), url("https://webfonts.zohowebstatic.com/lato2medium/font.ttf") format("truetype"), url("https://webfonts.zohowebstatic.com/lato2medium/font.svg#Lato2-Medium") format("svg");
}

@font-face {
  font-family: "LatoSemibold";
  font-weight: 600;
  font-style: normal;
  src: url("https://webfonts.zohowebstatic.com/lato2semibold/font.eot");
  src: url("https://webfonts.zohowebstatic.com/lato2semibold/font.eot?#iefix") format("eot"), url("https://webfonts.zohowebstatic.com/lato2semibold/font.woff2") format("woff2"), url("https://webfonts.zohowebstatic.com/lato2semibold/font.woff") format("woff"), url("https://webfonts.zohowebstatic.com/lato2semibold/font.ttf") format("truetype"), url("https://webfonts.zohowebstatic.com/lato2semibold/font.svg#Lato2-Semibold") format("svg");
}

@font-face {
  font-family: "LatoBold";
  font-weight: 700;
  font-style: normal;
  src: url("https://webfonts.zohowebstatic.com/lato2bold/font.eot");
  src: url("https://webfonts.zohowebstatic.com/lato2bold/font.eot?#iefix") format("eot"), url("https://webfonts.zohowebstatic.com/lato2bold/font.woff2") format("woff2"), url("https://webfonts.zohowebstatic.com/lato2bold/font.woff") format("woff"), url("https://webfonts.zohowebstatic.com/lato2bold/font.ttf") format("truetype"), url("https://webfonts.zohowebstatic.com/lato2bold/font.svg#Lato2-Bold") format("svg");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pd-0 {
  padding: 0 !important;
}

.mg-0 {
  margin: 0 !important;
}

.mgr-1 {
  margin-right: 1rem;
}

.hr-2 {
  height: 32px !important;
}

.f13 {
  font-size: 1.3rem;
}
.lh-2{
  line-height: 2;
}
.w-i {
  width: initial !important;
}
.hover-border:hover{
  border: 1px solid #1DBF7C !important;
}
.app-form-login {
  width: 400px;
  min-height: 500px;
  background-color: #f5f5f5;
  padding: 30px;
  margin: 30px auto;
  border-radius: 10px;
}
.app-form-login .form-control{
  font-size: 1.5rem !important; 
}
/* header */
.header {
  padding: 0;
  display: flex;
  height: 4.8rem;
  position: relative;
  z-index: 2;
  background: #223750;
  box-shadow: 0px 1px 1px #00000033;
  color: #BCCDE0;
}

.logo-header {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-right: 4rem;
  padding: 1.2rem 0 1.2rem 0;
}

.search-header {
  border-radius: 18px;
  width: 35rem;
  max-height: 3.2rem;
  transition: all 0.15s ease-in-out 0s;
  border: 1px solid #445D7E;
  align-self: center;
  display: flex;
  background: #283F5C;
}

.search-header:hover {
  border: 1px solid #1DBF7C !important;
}

.search-header .search-select {
  min-width: 6.5rem;
  max-width: 160px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.search-header .search-select span {
  margin: 0 10px;
  color: #fff;
}

.search-header .search-input {
  padding: 1rem 1.5rem;
  border: none;
  border-left: 1px solid #445D7E;
  background-color: transparent;

}

.search-header .search-input:focus-visible {
  outline: none;
}

.search-header .search-input::placeholder {
  color: #fff;
  opacity: 0.7;
}

.info-header {
  width: 35%;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  line-height: 48px;
}

.bigin-logo {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 24px;
  background: url(https://js.zohostatic.com/ignite/6399146/6398226/biginclient/ui-assets/images/logo/bigin-logo.svg) 0 center no-repeat transparent;
}

.bigin-logo-text {
  font-family: "zoho-puvi-regular", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
  margin-left: 0.8rem;
  position: relative;
  color: #fff;
}

/* main-body */

.main-body {
  display: flex;
  height: calc(100vh - 48px);
}

.component-content {
  width: calc(100% - 80px);
}
.com-content{
  width: calc(100% - 40px);
}
.com-system {
  width: 40px;
  background-color: #223853;
  height: 100%;
}

.con-system-text {
  width: 100%;
  height: 100%;
  color: #BCC9DE;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-component {
  padding: 1.2rem 2rem;
  background: #fff;
}

.header-contact {
  padding: 0.8rem 2.5rem 0.8rem;
}

.left-menu {
  width: 80px;
  height: 100%;
  overflow-y: hidden;
  background: #172D46;
  padding: 0.8rem 0.4rem;
}

.left-menu-ul {
  padding-left: 0px;
}

.left-menu-ul li {
  list-style: none;
  margin-bottom: 0.8rem;
  text-align: center;
  position: relative;
}

.left-menu-ul li>a {
  color: #8DA4BE;
  width: 100%;
  height: calc(80px - 0.8rem);
  padding: 1rem 0.3rem 1rem 0;
  font-size: 1.2rem;
  font-family: "LatoSemibold", sans-serif;
  display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-menu-ul li.active {
  background-color: #27B679;
  border-radius: 10px;
}

.left-menu-ul li.active a {
  color: #fff;
}

.left-menu-ul li.active .icon-ct {
  background-color: #fff;
}

.left-menu-ul li:hover a {
  color: #27B679;
}

.left-menu-ul li:hover .icon-ct {
  background-color: #27B679;
}

.left-menu-ul li:hover {
  background-color: #253D5A;
  border-radius: 10px;
}


.btn-ct {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50% !important;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e4f1eb !important;
  border: 1px solid #e4f1eb;
  transition: all 0.2s ease-in;
}

.header-component-select {
  border-radius: 16px;
  border: 1px solid #bdc8d3;
  background: #fff;
  transition: all 0.15s ease;
  width: 160px;
  height: 32px;
  padding: 0.6rem 2.5rem 0.6rem 1.5rem;
  font-family: "LatoBold", sans-serif;
  appearance: none;
  line-height: 1.8rem;
}

.header-component-select:focus {
  border: 1px solid #bdc8d3;
  outline: none;
}

.btn-contact {
  color: #fff !important;
  padding-right: 10px !important;
  border-right: 0;
  background-color: #23ae73;
  background-image: linear-gradient(to top, #11995f, #11995f 1%, #27b679);
  border: solid 0px transparent !important;
  border-radius: 16px 0 0 16px !important;
  font-size: 1.5rem !important;
  height: 32px;
}

.btn-contact-down {
  background-image: linear-gradient(to top, #0e9059, #0e9059 1%, #1da86d);
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-size: 1.5rem !important;
  border-radius: 0 16px 16px 0 !important;
  color: #fff;
  height: 32px;
}

.dd-arrow-down-white {
  position: relative;
  width: 12px;
  height: 4px;
  display: block;
}

.dd-arrow-down-white::after {
  border-color: #fff transparent transparent transparent;
  border-width: 4px 4px 0 4px;
  content: '';
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  left: 2px;
  transition: all 0.35s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.btn-ct-tb,
.btn-ct-b {
  margin: 0;
  border-right: 1px solid #d3dbe3;
  background-image: linear-gradient(to top, #F5F8FA, #ffffff) !important;
  width: 3.2rem !important;
  height: 3.2rem !important;
  border-radius: 18px 0 0 18px !important;
  border: 1px solid #d3dbe3 !important;
}

.btn-ct-b {
  border-radius: 0 18px 18px 0 !important;
}

.btn-ct-tb.active,
.btn-ct-b.active {
  background-image: linear-gradient(to top, #E1E8ED, #E2E8ED 1%, #F5F8FA);

}

.btn-ct-tb.active .icon-h-tb,
.btn-ct-b.active .icon-h-b {
  background: #1b9d66 !important;
}

.btn-ct-filter {
  background-color: transparent !important;
  border: 1px solid #d3dbe3 !important;

}

.form-select-ct label {
  height: 32px;
  line-height: 32px;
}

.header-main {
  padding: 10px;
  background: #eaeef2;
  height: calc(100% - 2.4rem - 32px);
  overflow: auto;
}

.header-main-flex-item {
  width: 230px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-main-flex-item:hover .item-gd {
  display: block;
  bottom: 0;
}

.flex-item-top {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  font-size: 1.5rem;
  padding: 8px 15px 8px 15px;
  border-top: 3px solid var(--color);
  background-color: #fff;
  margin-bottom: 10px
}

.text-dot {
  border-radius: 50%;
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #CDD5DD;
}

.flex-item-bot {
  background: #F9FBFC;
  height: 100%;
}

.flex-item-top-text {
  width: calc(100% - 50px);
}

.flex-item-bot-cont {
  background-color: #fff;
  border-bottom: 1px solid #E5EBEF;
  padding: 8px 15px 8px 15px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contact-main {
  border-top: 1px solid #f5f5f5;
  display: flex;
  height: calc(100% - 50px - 1.6rem);
}

.contact-main-left {
  width: 30%;
  background-color: #fff;
  padding: 1.5rem 1rem 1.5rem 2.5rem;
  border-right: 1px solid #E5EBEF;
  overflow: auto;
}

.contact-main-right {
  width: 70%;
  background-color: #fff;
  height: calc(100% - 44px);
}
.contact-main-right .tab-content{
  height: 100%;
}
.div-contact {
  padding: 0.7rem 0;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  margin-left: -0.5rem;
}

.contact-tk-text {
  padding: 0.6rem 0 0.6rem 0.5rem;
}

.contact-title {
  font-family: "LatoBold";
  font-size: 1.6rem;
  color: #212129;
  text-transform: capitalize;
}

.nav-ct {
  height: 44px;
  line-height: 44px;
  padding-left: 20px !important;
  padding-right: 6rem;
  border: none !important;
  border-bottom: 1px solid #f0f5f8;
  overflow: hidden;
}

.nav-ct .nav-link:focus,
.nav-ct .nav-link:hover {
  border-color: initial !important;
}

.nav-ct .nav-link {
  border: none !important;
  padding: 0rem 1rem !important;
  position: relative;
  margin-right: 15px;
  color: #000;
  font-family: "LatoSemibold";
}

.nav-ct .nav-item .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  transform-origin: bottom right;
  transform: scaleX(0);
  background-color: #23ae73;
  border-radius: 8px 8px 0 0;
}

.nav-ct .nav-item .nav-link.active::before {
  transform-origin: bottom left;
  transform: scaleX(1);
  transition: transform 0.25s ease;
}
.nav-ct-res{
  position: absolute;
  top: 5px;
  right: 6rem;
  display: none;
}
.nav-ct-res .dropdown-toggle::after{
  display: none;
}
.tab-content-ct {
  padding: 1.5rem 1.5rem 0 1.5rem;
  background: #eaeef2;
}

.tab-content-ct .tab-pane {
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #fff;
}

.contact-base-item {
  padding: 0.6rem 0 0.6rem 0.5rem;
  position: relative;
}
.contact-base-item:hover > .icon-edit-u{
  opacity: 1;
}
.contact-base-item >  .icon-edit-u{
  position: absolute;
  right: 10px;
  top: 7px;
}
.contact-tag .tag {
  padding: 3px 12px;
  border-radius: 12px;
  font-size: 1.4rem;
  background: #eff2f5;
  border: 1px solid #f0f5f8;
  margin: 0.7rem 1rem 0 0;
  display: inline-block;
}

.contact-des-text p {
  margin-bottom: 2px;
}

.oder-tab {
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #E5EBEF;
  border-top: 1px solid #E5EBEF;
  width: 52px;
  background: #fff;
  display: inline-block;
  text-align: center;
  height: 44px;
  line-height:40px;
}

.user-info {
  height: 50px;
}

.user-info-av {
  font-size: 1.6rem;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  box-shadow: 0 0 2px #fff;
  background-color: #BBD2EF;
  margin-right: 5px;
}

.user-i-name {
  margin: -0.2rem;
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  font-size: 1.8rem;
  font-family: "LatoBold", sans-serif;
  line-height: 1.5rem;
}

.user-i-name:hover .icon-edit-u {
  opacity: 1;
}

.user-info-name {
  padding: 0.5rem 3rem 0.5rem 0.5rem;
}
.user-info-name:hover>.icon-h-wrapper ,.user-info-name:hover>.icon-edit-u {
  opacity: 1;
}
.hover-element:hover {
  background-color: #f5f8fa;
  transition: 0.2s all;
  border-radius: 2px;
}

.btn-send-m {
  border-radius: 16px !important;
  font-family: "LatoBold", sans-serif;
}

.btn-send-m .icon-emai {
  background-color: #fff;
  margin-right: 0 !important;

}

.stats-bullet-blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  margin-right: 4px;
  background: #6dbbed;
}

.item-gd {
  position: absolute;
  bottom: -36px;
  display: none;
  left: 0;
  transition: 0.2s all;
  background: #F9FBFC;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.06);
  width: calc(100% - 10px);
  line-height: 36px;
  height: 36px;
}

.item-gd .d-flex>span:first-child {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 1rem;
  color: #1980d8;
  font-family: "LatoSemibold", sans-serif;
  width: calc(100% - 40px);
}

.fb {
  font-family: "Latobold", sans-serif;
}

.item-empty {
  position: relative;
  padding: 1.2rem;
  top: calc(50% - 40px);
  transform: translateY(-50%);
  color: #717179;
}

.div-icon-exp {
  border-left: 1px solid #E5EBEF;
  height: 36px;
  display: inline-block;
}

.time-tab-title {
  padding: 2rem 2rem 0;
}

.note-count {
  padding: 2px 5px;
  border-radius: 12px;
  text-align: center;
  min-width: 18px;
  position: absolute;
  right: 0;
  top: 16px;
  font-size: 1.2rem;
  line-height: 1;
  background-color: #E2F3FC;
}
.time-empty-state {
  color: #717179;
  font-size: 1.5rem;
  font-weight: normal;
}