.icon-ct {
    -webkit-mask-image: url(https://js.zohostatic.com/ignite/6399146/6398226/biginclient/ui-assets/images/sprite-images/bigin-svgicon.svg);
    mask-image: url(https://js.zohostatic.com/ignite/6399146/6398226/biginclient/ui-assets/images/sprite-images/bigin-svgicon.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: 24px;
    width: 23px;
    background-color: #fff;
    vertical-align: middle;
    display: block;
    margin: 0 auto;
}

.icon-contact {
    -webkit-mask-position: -632px -135px;
    mask-position: -632px -135px;
}

.icon-ht {
    -webkit-mask-position: -633px -2px;
    mask-position: -633px -2px;
    ;
}

.icon-h-contact {
    -webkit-mask-position: -590px -87px;
    mask-position: -590px -87px;
    height: 12px;
    width: 16px;
    background: #1b9d66 !important;

}

.icon-h-more {

    -webkit-mask-position: -592px -24px;
    mask-position: -592px -24px;
    width: 5px;
    height: 17px;
    background: #1b9d66 !important;
}

.icon-h-tb {
    -webkit-mask-position: -590px -65px;
    mask-position: -590px -65px;
    width: 14px;
    height: 15px;
    background: #4B556A !important;
}

.icon-h-b {
    -webkit-mask-position: -590px -46px;
    mask-position: -590px -46px;
    width: 15px;
    height: 14px;
    background: #4B556A !important;
}

.icon-h-filter {
    width: 18px;
    height: 14px;
    -webkit-mask-position: -10px -292px;
    background-color: #4B556A;
    mask-position: -10px -292px;
}

.icon-emai {
    width: 18px;
    height: 16px;
    -webkit-mask-position: -10px -260px;
    mask-position: -10px -260px;
    display: inline-block;
    background-color: #4B556A;
    margin-right: 1rem
}

.icon-phone {
    width: 14px;
    height: 17px;
    -webkit-mask-position: -39px -259px;
    mask-position: -39px -259px;
    display: inline-block;
    background-color: #4B556A;
    margin-right: 1rem;
}

.icon-mobile {
    width: 18px;
    height: 17px;
    -webkit-mask-position: -66px -259px;
    mask-position: -66px -259px;
    display: inline-block;
    background-color: #4B556A;
    margin-right: 1rem
}

.icon-mobile- {
    width: 20px;
    height: 15px;
    -webkit-mask-position: -116px -260px;
    mask-position: -116px -260px;
    display: inline-block;
    background-color: #4B556A;
    margin-right: 1rem
}

.icon-address {
    width: 16px;
    height: 20px;
    -webkit-mask-position: -91px -259px;
    mask-position: -91px -259px;
    display: inline-block;
    background-color: #4B556A;
    margin-right: 1rem
}

.icon-plus {
    width: 12px;
    height: 12px;
    background-color: #1980D8;
    -webkit-mask-position: -96px -426px;
    mask-position: -96px -426px;
    display: inline-block;
}

.icon-order-tab {
    width: 22px;
    height: 21px;
    background-color: #4B556A;
    -webkit-mask-position: -13px -320px;
    mask-position: -13px -320px;
    display: inline-block;
}

.icon-h-arrleft {
    width: 25px;
    height: 16px;
    display: inline-block;
    background-color: #4B556A;
    -webkit-mask-position: -10px -457px;
    mask-position: -10px -457px;
    margin: auto 0;
    margin-right: 1.5rem;
}

.icon-h-star {
    width: 14px;
    height: 14px;
    display: inline-block;
    -webkit-mask-position: -348px -82px;
    mask-position: -348px -82px;
    background-color: #FB8926;
    margin-right: 4px;
}
.h-ins{
    padding: 4px;
    display: inline-block;
    background-color: #d2d6d9;
    border-radius: 50%;
    position: absolute;
    top: 0;
}
.icon-h-ins{
    width: 18px;
    height: 18px;
    display: inline-block;
    -webkit-mask-position: -262px -356px;
    mask-position: -262px -356px;
    background-color: #fff;
}
.icon-h-wrapper {
    width: 18px;
    height: 16px;
    display: inline-block;
    background-color: #4B556A;
    -webkit-mask-position: -295px -7px;
    mask-position: -295px -7px;
    background-color: #4B556A;
    margin: 0 8px;
    opacity: 0;
}

.icon-h-edit {

    -webkit-mask-position: -590px -105px;
    mask-position: -590px -105px;
    width: 16px;
    height: 15px;
    background: #1b9d66 !important;
}

.icon-select {
    width: 12px;
    height: 4px;
    display: inline-block;
    -webkit-mask-position: -616px -166px;
    mask-position: -616px -166px;
    background: #9CADC9 !important;
    margin-left: 0 !important;
}
.icon-h-notify{
    margin: auto 0;
    width: 15px;
    height: 17px;
    display: inline-block;
    -webkit-mask-position: -154px -47px;
    mask-position: -154px -47px;
    background: #fff !important;
    position: relative;
}

.icon-h-war{
    margin: auto 0;
    width: 18px;
    height: 18px;
    display: inline-block;
    -webkit-mask-position: -188px -47px;
    mask-position: -188px -47px;
    background: #fff !important;
    vertical-align: unset;
}
.icon-h-setting{
    margin: auto 0;
    width: 18px;
    height: 17px;
    display: inline-block;
    -webkit-mask-position: -260px -47px;
    mask-position: -260px -47px;
    background: #fff !important;
}
.icon-h-plus{
    margin: auto 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    -webkit-mask-position: -225px -46px;
    mask-position: -225px -46px;
    background: #fff !important;
}
.icon-ava {
    display: inline-block;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    background-color: #1b9d66;
    margin: auto;
    text-align: center;
    line-height: 3.2rem;
    color: #fff;
}
.icon-exp{
    width: 17px;
    height: 17px;
    display: inline-block;
    -webkit-mask-position: -108px -627px;
    mask-position: -108px -627px;
    background: #4B556A !important;
    margin: 9.5px;
}
.icon-edit-u{
    display: inline-block;
    margin: 0 10px;
    opacity: 0;
}
.icon-select-ct{
    position: absolute;
    right: 6px;
    top: 14px;
}
.icon-h-r {
    -webkit-mask-position: -79px -457px;
    mask-position: -79px -457px;
    width: 11px;
    height: 16px;
    background: #4B556A !important;
}

.icon-h-l {
    -webkit-mask-position: -93px -457px;
    mask-position: -93px -457px;
    width: 11px;
    height: 16px;
    background: #4B556A !important;
}