@media only screen and (max-width: 650px){
    .info-header{
        display: none;
    }
    .app-form-login{
        width: 90%;
    }
}
@media only screen and (max-width: 768px){
    .btn-ct-filter{
        display: none !important;
    }
    .user-info-name{
        padding-right:0 !important ;
    }
    .contact-main{
        height: calc(100% - 50px - 1.6rem - 32px);
    }
    .nav-ct-res{
        display: block;
    }
}
@media only screen and (max-width: 991px){
    .form-select-ct{
        display: none;
    }
}
@media only screen and (max-width: 1024px){
   
    .info-header span:first-child{
        display: none;
    }
    .div-contact{
        min-width: 250px;
    }
    .contact-main{
        flex-direction: column;
        overflow:auto;
    }
    .contact-main-left{
        width: 100%;
        overflow: unset;
    }
    .contact-main-right{
        width: 100%;
    }
    .tab-content-ct{
        padding-bottom: 1.5rem;
    }
}